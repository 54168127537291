import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { ProjectSummary } from "../../components/projects/ProjectSummary";

const BrooksideSubdivision = () => (
  <Layout>
    <SEO
      title="Opaki Road Stormwater Upgrades"
      image={"/opaki2.jpg"}
      description="The works involved site clearing and demolition, constructing a new stormwater network on Opaki Road and Oxford Street."
    />

    <div className="row g-0">
      <div className="col-lg-6 d-flex align-items-center justify-content-center">
        <div className="content-box">
          <h1 className="section-heading section-heading--left">
            Opaki Road Stormwater Upgrades
          </h1>
          <ProjectSummary
            name="Opaki Road Stormwater Upgrades"
            type="Stormwater Upgrades"
            location="Masterton, Wairarapa"
            client="Masterton District Council"
            engineer="Masterton District Council"
          />
          <p>
            The works involved site clearing and demolition, constructing a new
            stormwater network on Opaki Road and Oxford Street. Approx. 290m of
            new RCRRJ 225 - 375mm concrete pipe was installed along with 6 1050
            manholes, the construction of a new modular soakage pit and
            reinstatement to MDC and NZTA standards. Approx. 400m3 was excavated
            to install new Rain Smart Modular System.
          </p>
        </div>
      </div>
      <div className="col-lg-6 d-flex align-items-center justify-content-center">
        <StaticImage
          aspectRatio={16 / 16}
          transformOptions={{ cropFocus: "center" }}
          objectPosition="50% 50%"
          src="../../images/opaki2.jpg"
          alt="stormwater"
        />
      </div>
    </div>
  </Layout>
);

export default BrooksideSubdivision;
